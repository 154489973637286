import { ThumbUp } from "@mui/icons-material";
import LoadingButton from "@mui/lab/LoadingButton";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControlLabel, Paper, Switch } from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";
import { React, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import Swal from 'sweetalert2';
import { accountActions, discussionActions, listonespaceswitchActions, profilActions, quoteswitchActions, socialMediaActions, spaceswitchActions } from "../actions";
import { languageswitchConstants, listonespaceswitchConstants, newsConstants, previewswitchConstants, publisherswitchConstants, quoteswitchConstants, spaceswitchConstants, switchConstants } from "../constants";
import { Spinner } from "../spinner/spinner";

import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";

import { makeStyles } from "@material-ui/core/styles";
import Draggable from 'react-draggable';
import { previewswitchActions } from "../actions/previewswitch.actions";
import { publisherActions } from "../actions/publisher.actions";
import { replaceWord } from "../helpers";
import { Logo } from "./Item";
import { SplitButton } from "./Switch";

const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
        toast.onmouseenter = Swal.stopTimer;
        toast.onmouseleave = Swal.resumeTimer;
    }
});

export const SearchBox = (props) => {
    const l = useSelector(state => state.account.loading);
    const [loading, setLoading] = useState(l);
    const st = useSelector(state => state.account.name);
    // const [searchText, setSearchText] = useState(st);
    const menuSwitch = useSelector(state => state.menuswitch);
    const lang = useSelector(state => state.languageswitch);
    const valueRef = useRef('')
    const dispatch = useDispatch();

    const searchText = useSelector(state => state.news.name);
    const n = useSelector(state => state.news.newsList);
    const [newsList, setNewsList] = useState(n);
    const ln = useSelector(state => state.news.loading);
    const [loadingNews, setLoadingNews] = useState(ln);
    const qs = useSelector(state => state.quoteswitch);
    const [quoteswitch, setQuoteSwitch] = useState(qs);
    const pw = useSelector(state => state.previewswitch);
    const [previewswitch, setPreviewSwitch] = useState(pw);
    const d = useSelector(state => state.news.done);
    const [done, setDone] = useState(d);
    const e = useSelector(state => state.news.error);
    const [error, setError] = useState(e);
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => {
        dispatch({ type: newsConstants.SEARCH_CLEAR });
        setOpen(false);
    }

    const t = useSelector(state => state.themeswitch);
    const [theme, setTheme] = useState(t);

    const handleCloseForPreview = () => {
        setOpen(false);
    }

    const ss = useSelector(state => state.spaceswitch);
    const lop = useSelector(state => state.listonespaceswitch);

    const publisherSwitch = useSelector(state => state.publisherswitch);

    const [urlp, setUlrp] = useState(localStorage.getItem("urlparam"))


    // const handleChange = (e) => {
    //     setSearchText(e.target.value);
    // }

    const handleSubmit = (e) => {
        e.preventDefault();
        // console.log(valueRef.current.value)

        var input = valueRef.current.value;

        if (e.target.id == "showAll") {
            input = "show__all"
        }


        if (input && menuSwitch === switchConstants.SOCIAL_MEDIA_NR) {
            switch (lang) {
                case languageswitchConstants.ENGLISH_NR:
                    dispatch(socialMediaActions.searchNews(input));
                    break;
                case languageswitchConstants.SPANISH_NR:
                    dispatch(socialMediaActions.searchNewsES(input));
                    break;
                case languageswitchConstants.DEUTSCH_NR:
                    dispatch(socialMediaActions.searchNewsDE(input));
                    break;
                case languageswitchConstants.FRANCAIS_NR:
                    dispatch(socialMediaActions.searchNewsFR(input));
                    break;
                case languageswitchConstants.PORTUGUESE_NR:
                    dispatch(socialMediaActions.searchNewsPT(input));
                    break;
                case languageswitchConstants.HINDI_NR:
                    dispatch(socialMediaActions.searchNewsHI(input));
                    break;
                case languageswitchConstants.INDONESIAN_NR:
                    dispatch(socialMediaActions.searchNewsINDO(input));
                    break;
                case languageswitchConstants.RUSSIAN_NR:
                    dispatch(socialMediaActions.searchNewsRU(input));
                    break;
                case languageswitchConstants.CHINESE_NR:
                    dispatch(socialMediaActions.searchNewsCH(input));
                    break;
                case languageswitchConstants.JAPANESE_NR:
                    dispatch(socialMediaActions.searchNewsJP(input));
                    break;
                case languageswitchConstants.ARABIC_NR:
                    dispatch(socialMediaActions.searchNewsAR(input));
                    break;
                case languageswitchConstants.ITALIAN_NR:
                    dispatch(socialMediaActions.searchNewsIT(input));
                    break;
                case languageswitchConstants.TURKISH_NR:
                    dispatch(socialMediaActions.searchNewsTR(input));
                    break;
                case languageswitchConstants.DUTCH_NR:
                    dispatch(socialMediaActions.searchNewsNL(input));
                    break;
                case languageswitchConstants.GREEK_NR:
                    dispatch(socialMediaActions.searchNewsGR(input));
                    break;
            }
        }

        if (input && menuSwitch === switchConstants.PUBLISHERS_NR) {
            var query = replaceWord(input, "_", " ")

            if (publisherSwitch == publisherswitchConstants.ALL_PUBLISHERS_NR) {
                dispatch(publisherActions.getPublisherList(query, true));
            }

            if (publisherSwitch == publisherswitchConstants.PINNED_NR) {
                dispatch(publisherActions.getMyPublisherList(query, true));
            }
        }

        if (input && menuSwitch === switchConstants.ACCOUNT_NR) {
            dispatch(accountActions.searchProfiles(input));
        }

        if (input && menuSwitch === switchConstants.HACKED_NR) {
            if (input.trim().match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)) {
                dispatch(accountActions.searchBreaches(input.trim()));
            } else {
                Toast.fire({
                    icon: "warning",
                    title: "Please write correctly your email"
                });

                return;
            }
        }

        if (input && menuSwitch === switchConstants.SPACE_NR) {
            var query = replaceWord(input, "_", " ")

            if (lop == listonespaceswitchConstants.SPACE_LIST_NR) {
                if (ss == spaceswitchConstants.FEED_NR) {
                    dispatch(discussionActions.getFeedFilter())
                    dispatch(discussionActions.getFeed(query, true))
                }
                if (ss == spaceswitchConstants.ALL_SPACES_NR) {
                    dispatch(listonespaceswitchActions.switchSpace(listonespaceswitchConstants.SPACE_LIST))
                    dispatch(spaceswitchActions.changeSpace(spaceswitchConstants.FEED))
                    dispatch(discussionActions.getFeedFilter())
                    dispatch(discussionActions.getFeed(query, true))
                }
                if (ss == spaceswitchConstants.MY_SPACE_NR) {
                    dispatch(discussionActions.getMyDiscussionList(query, true));
                }
                if (ss == spaceswitchConstants.LIGHT_NR) {
                    dispatch(discussionActions.getLightDiscussionList(query, true));
                }
                if (ss == spaceswitchConstants.STAR_NR) {
                    dispatch(profilActions.getStarProfilList(query, true));
                }
                if (ss == spaceswitchConstants.MESSAGE_NR) {
                    dispatch(profilActions.getMyTalkers(query, true));
                }
                if (ss == spaceswitchConstants.FIND_NR) {
                    dispatch(profilActions.getProfilList("", query, true));
                }
            }

            if (lop == listonespaceswitchConstants.ONE_SPACE_NR) {
                if (ss == spaceswitchConstants.ALL_SPACES_NR) {
                    dispatch(discussionActions.getDiscussionList(urlp, query, true));
                }
                if (ss == spaceswitchConstants.MY_SPACE_NR) {
                    dispatch(discussionActions.getMyDiscussionList(query, true));
                }
                if (ss == spaceswitchConstants.LIGHT_NR) {
                    dispatch(discussionActions.getLightDiscussionList(query, true));
                }
                if (ss == spaceswitchConstants.STAR_NR) {
                    dispatch(profilActions.getStarProfilList(query, true));
                }
                if (ss == spaceswitchConstants.MESSAGE_NR) {
                    dispatch(profilActions.getMyTalkers(query, true));
                }
                if (ss == spaceswitchConstants.FIND_NR) {
                    dispatch(profilActions.getProfilList("", query, true));
                }
            }
        }
    }

    const switchHandler = (e) => {
        if (quoteswitch) {
            //console.log("asaasas")
            dispatch(quoteswitchActions.changeMode(quoteswitchConstants.NO))
        } else {
            dispatch(quoteswitchActions.changeMode(quoteswitchConstants.YES))
        }
    }

    const previewHandler = (e) => {
        if (previewswitch) {
            //console.log("asaasas")
            dispatch(previewswitchActions.changePreview(previewswitchConstants.NO))
        } else {
            dispatch(previewswitchActions.changePreview(previewswitchConstants.YES))
        }
    }

    /*     useEffect(() => {
            valueRef.current.focus();
        }, [menuSwitch]);
    
        useEffect(() => {
            valueRef.current.focus();
        }, []); */

    useEffect(() => {
        setNewsList(n);
    }, [n]);

    useEffect(() => {
        setLoading(l);
    }, [l]);

    useEffect(() => {
        setLoadingNews(ln);
    }, [ln]);

    useEffect(() => {
        setError(e);
    }, [e]);

    useEffect(() => {
        setDone(d);
    }, [d]);

    useEffect(() => {
        setQuoteSwitch(qs);
    }, [qs]);

    useEffect(() => {
        setPreviewSwitch(pw);
    }, [pw]);

    useEffect(() => {
        setTheme(t);
    }, [t])

    useEffect(() => {
        if (d) {
            if (e) {
                dispatch({ type: newsConstants.SEARCH_FAILURE_DONE });
                Swal.fire({
                    icon: 'error',
                    title: 'An error has occurred. Please try again ...',
                    showConfirmButton: false,
                    timer: 1500
                })
            } else {
                if (n && n.news) {
                    setNewsList(n);
                }
                handleOpen();
            }
        }
    }, [d]);

    var placeholder = "Search social news...";

    if (menuSwitch === 0) {
        placeholder = "Search social news...";
    } else if (menuSwitch === 1) {
        placeholder = "Search publishers' news...";
    } else if (menuSwitch === 2) {
        placeholder = "Search social accounts...";
    } else if (menuSwitch === 4) {
        if (lop == listonespaceswitchConstants.SPACE_LIST_NR) {
            if (ss == spaceswitchConstants.FEED_NR) {
                placeholder = "Search News in Social Space...";
            }
            if (ss == spaceswitchConstants.ALL_SPACES_NR) {
                placeholder = "Search News in Social Space...";
            }
            if (ss == spaceswitchConstants.MY_SPACE_NR) {
                placeholder = "Search My Shared News in Social Space...";
            }
            if (ss == spaceswitchConstants.LIGHT_NR) {
                placeholder = "Search Light News in Social Space...";
            }
            if (ss == spaceswitchConstants.STAR_NR) {
                placeholder = "Search Someone in Social Space...";
            }
            if (ss == spaceswitchConstants.MESSAGE_NR) {
                placeholder = "Search Someone in Social Space...";
            }
            if (ss == spaceswitchConstants.FIND_NR) {
                placeholder = "Search Someone in Social Space...";
            }
        }

        if (lop == listonespaceswitchConstants.ONE_SPACE_NR) {
            if (ss == spaceswitchConstants.ALL_SPACES_NR) {
                placeholder = "Search News in Social Space...";
            }
            if (ss == spaceswitchConstants.MY_SPACE_NR) {
                placeholder = "Search My Shared News in Social Space...";
            }
            if (ss == spaceswitchConstants.LIGHT_NR) {
                placeholder = "Search My Light News in Social Space...";
            }
            if (ss == spaceswitchConstants.STAR_NR) {
                placeholder = "Search Someone in Social Space...";
            }
            if (ss == spaceswitchConstants.MESSAGE_NR) {
                placeholder = "Search Someone in Social Space...";
            }
            if (ss == spaceswitchConstants.FIND_NR) {
                placeholder = "Search Someone in Social Space...";
            }
        }
    }

    return (
        <div class="container">
            <form>
                <Spinner show={loadingNews} />
                <DraggableDialog searchText={searchText} news={newsList && newsList.news ? newsList.news : []} open={open} handleClose={handleClose} />
                <div class="row">
                    <div class="col-6 col-md-2"></div>
                    <div class="col-12 col-md-10" style={{ padding: "0" }}>
                        <div class="container">
                            <TextField
                                id="outlined-basic"
                                variant="outlined"
                                // label=" "
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <ThumbUp style={{ color: "#166fe5" }} />
                                        </InputAdornment>
                                    ),
                                    style: { color: theme === 'dark' ? '#fff' : '#000' } // light
                                }}
                                sx={{
                                    '& legend': { display: 'none' },
                                    '& fieldset': { top: 0 },
                                }} // Remove the label on the top of the searchbar
                                inputRef={valueRef}
                                placeholder={placeholder}
                                // onchange = {handleChange}
                                fullWidth
                                color="info" // dark mode
                                focused // dark mode                           
                                onKeyDown={(e) => {
                                    if (e.key === "Enter") {
                                        handleSubmit(e);
                                    }
                                }}
                            />
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-6 col-md-2"></div>
                    <div class="col-12 col-md-10" style={{ padding: "0" }}>
                        <div class="container">
                            <LoadingButton style={{ marginTop: "20px" }}
                                onClick={e => handleSubmit(e)} loading={loading} loadingIndicator="Loading…" variant="contained">
                                Search
                            </LoadingButton>
                            {menuSwitch === switchConstants.SOCIAL_MEDIA_NR ?
                                <Button id="showAll" style={{ marginTop: 20, marginLeft: 20 }} variant="contained" onClick={e => handleSubmit(e)}>ALL</Button>
                                : null}
                            {/* {menuSwitch === switchConstants.SOCIAL_MEDIA_NR && <FormControlLabel
                                value="preview"
                                control={<Switch color="primary" checked={previewswitch} onChange={previewHandler} id="preview" />}
                                label="Preview"
                                labelPlacement="preview"
                                style={{ marginTop: 20, marginLeft: 10 }}
                            />} */}
                            {menuSwitch === switchConstants.SOCIAL_MEDIA_NR || menuSwitch === switchConstants.PUBLISHERS_NR ? <FormControlLabel
                                value="quote"
                                control={<Switch color="primary" checked={quoteswitch} onChange={switchHandler} id="quote" />}
                                label="Quotes"
                                labelPlacement="quote"
                                style={{ marginTop: 20, marginLeft: 10 }}
                            /> : null}
                            {/* <Button style={{ marginTop: "20px" }} variant="contained" onClick={e => handleSubmit(e)}>Search</Button> */}
                        </div>
                        {/* {menuSwitch === 0 && <LanguageSwitch />} */}
                        {<SplitButton />}
                    </div>
                </div>
            </form>
        </div>
    );
}

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 350,
    height: 570,
    bgcolor: "#0D1F2D",
    color: "#fff",
    border: "2px solid #fff",
    borderRadius: "5px",
    boxShadow: 24,
    overflowY: "auto",
    overflowX: "hidden",
    p: 4,
    '@media (min-width: 600px)': {
        width: 500
    }
};

/*old component */
const BasicModal = ({ searchText, news, open, handleClose }) => {

    return (
        <div>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        {news.length} Results for {searchText}
                    </Typography>
                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        {news.length !== 0 ?
                            news.map((d) => (
                                <p class="marginItem" style={{ marginBottom: 5, marginTop: 5 }}><span class="bg-primary bg-gradient text-white" style={{ padding: 2, borderRadius: 5 }} ><b>{d.timeago}</b></span>{"  "}<a target="_blank" style={{ color: "#fff", textDecoration: "underline dotted" }} href={d.url}>{d.title}</a>{"  "}<SocialMark name={d.type} /></p>
                            )) :
                            <p class="marginItem" style={{ marginBottom: "0" }}>Sorry, no Results for {searchText}</p>
                        }
                    </Typography>
                </Box>
            </Modal>
        </div>
    );
}

const useStyles = makeStyles(theme => ({
    paper: {
        overflowY: 'auto',
        overflowX: 'hidden',
        width: 350,

        '@media (min-width: 600px) and (max-width: 800px)': {
            width: 500
        },

        '@media (min-width: 800px)': {
            width: 600
        },
    },
    customizedButton: {
        backgroundColor: '#007bff',
        color: '#fff',
        float: 'right',
    }
}));

function PaperComponent(props) {
    return (
        <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
            <Paper {...props} />
        </Draggable>
    );
}

const DraggableDialog = ({ searchText, news, open, handleClose }) => {
    const classes = useStyles();
    const t = useSelector(state => state.themeswitch);
    const [theme, setTheme] = useState(t);

    useEffect(() => {
        setTheme(t);
    }, [t])

    var title = searchText == "show__all" ? "All News" : news.length + " Results for '" + searchText + "'"
    var sorry = searchText == "show__all" ? "Oops! Something went wrong while loading the news. Please try again later" : "Sorry, no Results for '" + searchText + "'"

    return (
        <div>
            <Dialog
                open={open}
                onClose={handleClose}
                PaperComponent={PaperComponent}
                aria-labelledby="draggable-dialog-title"
                classes={{ paper: classes.paper }}
                style={{ zIndex: 10000000000 }}
            >
                <DialogTitle style={{ cursor: 'move', color: '#0D1F2D' }} id="draggable-dialog-title">
                    <span style={{ color: '#166fe5' }}>{title}</span> {/* light */}
                    {/*<IconButton autoFocus onClick={handleClose} color="primary" className={classes.customizedButton}>
                        <CloseIcon />
                </IconButton> */}
                </DialogTitle>
                <DialogContent style={{ background: theme === 'dark' ? '#0D1F2D' : '#fff' }}> {/* light */}
                    <DialogContentText>
                        {news.length !== 0 ?
                            news.map((d) => (
                                <p class="marginItem" style={{ marginBottom: 5, marginTop: 10 }}><span class="bg-primary bg-gradient text-white" style={{ padding: 2, borderRadius: 5 }} ><b>{d.timeago}</b></span>{"  "}<a target="_blank" style={{ color: theme === 'dark' ? '#fff' : '#000', fontSize: "1.1rem", textDecoration: "underline dotted", cursor: "pointer" }} href={d.url}>{d.title}</a>{"  "}<SocialMark name={d.type} /></p>
                            )) :
                            <p class="marginItem" style={{ marginBottom: 5, marginTop: 10, color: theme === 'dark' ? '#fff' : '#000' }}>{sorry}</p>
                        }
                    </DialogContentText>{/* light */}
                </DialogContent>
                <DialogActions style={{ background: theme === 'dark' ? '#0D1F2D' : '#fff' }}>
                    <Button onClick={handleClose} variant="contained">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

const SocialMark = ({ name }) => {

    return (
        <span style={{ display: 'inline-block', color: "#fff", background: '#166fe5', padding: 6, borderRadius: 12 }}>
            <Logo name={name} />
        </span>
    );
}